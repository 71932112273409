<template>
  <app-loader></app-loader>
</template>

<script>
import { auth } from '@/services/auth.service';
import AppLoader from '@/components/common/AppLoader/AppLoader.vue';


export default {
  components: {
    AppLoader,
  },
  data() {},
  methods: {
    handleCallback() {
      auth.getManager().signinRedirectCallback()
        .then(
          function () {
            const route = localStorage.getItem('redirectUrl');
            if (route) {
              window.location.href = route;
            } else {
              window.location.href = '/';
            }
          });
    }

  },
  mounted() {
    this.handleCallback();
  }

};
</script>

<style></style>